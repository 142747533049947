
export default {
  name: 'UiAsideCard',
  props: {
    title: {
      type: String,
      default: null,
    },
    descriptions: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: 'light',
    },
    accent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer
    },
  },
}
