
const EXPERTS_LIMIT = 10
export default {
  name: 'BaseRecommendationsStreamsExperts',
  props: {
    title: {
      type: String,
      default: 'Вклад в журналы',
    },
    descriptions: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      experts: [],
      streams: [],
    }
  },
  async fetch() {
    await this.fetchExperts()
  },
  watch: {
    '$route.path'() {
      this.streams = []
      if (
        this.$route.name === 'navigator-streams-collection-url' &&
        this.$route.params?.url
      ) {
        this.fetchCollection()
      }
    },
  },
  methods: {
    userName(user) {
      if (user?.type === 'COMPANY') {
        return user?.company?.title ? user?.company?.title : user?.username
      } else {
        return user?.profile?.firstName && user?.profile?.lastName
          ? `${user?.profile.firstName} ${user?.profile.lastName}`
          : user?.username
      }
    },
    async fetchExperts() {
      this.experts = await this.$axios.$get('/experts/streams/recomended', {
        params: {
          streams: this.streams,
          limit: EXPERTS_LIMIT,
        },
      })
    },
    async fetchCollection() {
      const url = this.$route.params?.url

      if (url) {
        const fields = {
          streams: true,
        }
        const collection = await this.$axios.$get(`/collection/${url}`, {
          params: {
            filter: fields,
          },
        })

        if (collection) {
          this.streams = collection.streams
        }

        this.fetchExperts()
      }
    },
  },
}
