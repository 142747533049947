
export default {
  name: 'BaseImage',
  props: {
    src: {
      type: String,
      default: '',
    },
    size: {
      type: Object,
      default: () => {
        return {
          sm: 200,
          md: 400,
          lg: 1056,
        }
      },
    },
    alt: {
      type: String,
      default: null,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    full: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      url: 'https://img.megasreda.ru',
      // url: 'http://localhost:4010',
    }
  },
}
