import { render, staticRenderFns } from "./Experts.vue?vue&type=template&id=1220dfb4&"
import script from "./Experts.vue?vue&type=script&lang=js&"
export * from "./Experts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseUserAvatar: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/User/Avatar.vue').default,BaseSubscriptionLink: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Subscription/Link.vue').default,UiAsideCard: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Ui/Aside/Card.vue').default})
