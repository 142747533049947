
import mixin from './mixin.vue'
export default {
  name: 'BaseSubscriptionLink',

  mixins: [mixin],
  props: {
    accent: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default',
    },
  },
}
