
export default {
  props: {
    entity: {
      type: String,
      required: true,
    },
    entityType: {
      type: String,
      default: 'USER',
    },
    entityId: {
      type: [Number, String],
      default: null,
    },
    username: {
      type: String,
      default: null,
    },
    collection: {
      type: Object,
      default: null,
    },
    unSubscribeMessage: {
      type: String,
      default: null,
    },
    btnText: {
      type: String,
      default: null,
    },
  },
  computed: {
    isUserSubscribe: {
      get() {
        if (this.$auth.loggedIn) {
          switch (this.entity) {
            case 'AUTHOR':
              return !!this.$auth.user.subscriptions?.authors?.find(
                (subscription) => {
                  return subscription.author === this.entityId
                }
              )
            case 'STREAM':
              return !!this.$auth.user.subscriptions?.streams?.find(
                (subscription) => {
                  return subscription.stream === this.entityId
                }
              )
            case 'COLLECTION':
              if (this.collection) {
                const userSubscriptionsStreamIds =
                  this.$auth.user?.subscriptions?.streams?.map(
                    (item) => item.stream
                  )

                return !!this.collection.streams.every((stream) =>
                  userSubscriptionsStreamIds.includes(stream._id)
                )
              }
              return false

            default:
              break
          }
        }
        return false
      },
      set(value) {
        value ? this.subscribe() : this.unSubscribe()
      },
    },
    subscribeButtonText() {
      if (this.btnText) {
        return this.btnText
      } else if (this.entity === 'STREAM') {
        return 'Отслеживать'
      } else if (this.entity === 'AUTHOR' && this.entityType === 'COMPANY') {
        return 'Отслеживать'
      } else if (this.entity === 'COLLECTION') {
        return 'Подписаться на сообщество'
      }
      return 'Отслеживать'
    },
    unSubscribeButtonText() {
      if (this.entity === 'STREAM') {
        return 'Вы отслеживаете'
      } else if (this.entity === 'AUTHOR' && this.entityType === 'COMPANY') {
        return 'Вы отслеживаете'
      } else if (this.entity === 'COLLECTION') {
        return 'Вы подписаны на сообщество'
      }
      return 'Вы отслеживаете'
    },
  },
  methods: {
    async subscribe() {
      if (this.$auth.loggedIn) {
        await this.$axios.$post('/users/subscribe', {
          entity: this.entity,
          entityId: this.entityId,
        })
        this.$emit('subscribe')
        this.$eventBus.$emit('updateSubscriptions')
        await this.$auth.fetchUser()
      } else if (
        (this.entity === 'USER' || this.entity === 'AUTHOR') &&
        this.username
      ) {
        this.$router.push(`/${this.username}/welcome`)
      } else {
        this.$router.push('/register')
      }
    },
    async unSubscribe() {
      const isUnSubscribe = await this.$refs.confirmDialogue.show({
        title: 'Отменить подписку',
        message: this.unSubscribeMessage,
        okButton: 'Отменить подписку',
      })
      if (isUnSubscribe) {
        if (this.$auth.loggedIn) {
          await this.$axios.$post('/users/unsubscribe', {
            entity: this.entity,
            entityId: this.entityId,
          })

          this.$emit('unsubscribe')
          this.$eventBus.$emit('updateSubscriptions')
          await this.$auth.fetchUser()
        } else {
          this.$router.push('/register')
        }
      }
    },
  },
}
