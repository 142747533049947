
export default {
  name: 'BaseUserAvatar',
  props: {
    size: {
      type: String,
      default: 'default',
    },
    user: {
      type: [Object, Boolean, Array],
      default: null,
    },
    noLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    author() {
      const author = Array.isArray(this.user) ? this.user[0] : this.user
      return author
    },
    linkTitle() {
      if (this.user.type === 'COMPANY') {
        return `Профиль компании «${this.$author.authorName(
          this.author
        )}» на Мегасреде`
      } else {
        return `Профиль пользователя «${this.$author.authorName(
          this.author
        )}» на Мегасреде`
      }
    },
    imgSize() {
      switch (this.size) {
        case 'thin':
          return 16
        case 'light':
          return 24
        case 'small':
          return 32
        case 'middle':
          return 40
        case 'default':
          return 48
        case 'large':
          return 80
        case 'big':
          return 96

        default:
          return 96
      }
    },
    blockSize() {
      return {
        'w-4 h-4': this.size === 'thin',
        'w-6 h-6': this.size === 'light',
        'w-7.5 h-7.5': this.size === 'small',
        'w-10 h-10': this.size === 'middle',
        'w-10 h-10 phone-l:w-12 phone-l:h-12': this.size === 'default',
        'w-20 h-20': this.size === 'large',
        'w-24 h-24': this.size === 'big',
      }
    },
  },
}
